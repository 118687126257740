// Reset <fieldset>s and <legend>s
%fieldset-reset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;

  legend {
    padding: 0;
    display: table;
  }
}