.#{$mri-ns}form-block {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  max-width: $mri-form-block-max-width;
  margin-left: -2px;
  margin-right: -2px;
  min-height: 0;
  min-width: 0;
  flex-shrink: 0;
}

.#{$mri-ns}form-block__segment {
  display: inherit;
  flex-flow: inherit;
  align-items: inherit;
  flex-grow: 10;
  flex-shrink: 10;
  flex-basis: 100%;
}

.#{$mri-ns}form-block__control {
  margin: 0 0 16px 0;
}