.#{$mri-ns}background--bubbles {
  pointer-events: none;
  position: absolute;
  background-color: transparentize(mri-gray(90), 0.05);
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: none;
  overflow: hidden;
  z-index: -1;
  animation: fade-in-down $mri-transition-base;

  &.#{$mri-ns}is-hidden {
    opacity: 0;
    animation: fade-out-up $mri-transition-base;

    &::before, &::after {
      animation: fade-out-up $mri-transition-base;
      opacity: 0;
      transform: translateY(-20vh);
    }
  }

  &::before, &::after {
    content: "";
    pointer-events: none;
    z-index: -1;
    display: block;
    position: absolute;
    border-radius: 100%;
    opacity: 1;
    transform: translateY(0);
  }

  &:not(.#{$mri-ns}is-hidden)::before {
    width: 120vmax;
    height: 120vmax;
    top: calc(-120vmax + 40vh);
    left: -30vmax;
    border-radius: 100%;
    background-color: transparentize($mri-brand-blue, 0.5);
    animation: mri-ag-circles-1 2.5s cubic-bezier(0, 1, 1, 1);
    transform: translateZ(0);
  }

  &:not(.#{$mri-ns}is-hidden)::after {
    width: 100vmax;
    height: 100vmax;
    top: calc(-100vmax + 25vh);
    left: 50vw;
    background-color: transparentize($mri-brand-accent-blue, 0.5);
    animation: mri-ag-circles-2 2.75s cubic-bezier(0, 1, 1, 1);
    transform: translateZ(0);
  }
}

@keyframes mri-ag-circles-1 {
  0% {
    opacity: 0;
    transform: translateY(-20vh);
  }
  
  10% {
    opacity: 0;
    transform: translateY(-20vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes mri-ag-circles-2 {
  0% {
    opacity: 0;
    transform: translateY(-30vh);
  }

  20% {
    opacity: 0;
    transform: translateY(-30vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}