@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-down {
  from {
    transform: translateY(-1em);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-up {
  from {
    transform: translateY(1em);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}