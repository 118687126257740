// TODO: Comment out all style imports except for those we currently need
// Class namespace (e.g. 'mri' in '.#{$mri-ns}button'
$mri-ns: 'mri-' !default;

@import '@mri/scss-all/scss/functions/_all.scss';

@import '@mri/scss-all/scss/variables/_all.scss';

// @import '@mri/scss-all/scss/vendor';
@import '@mri/scss-all/scss/resets/_all.scss';

@import '@mri/scss-all/scss/animations/_all.scss';
@import '@mri/scss-all/scss/components/_all.scss';
@import '@mri/scss-all/scss/components/01-atoms/base/_base.scss';
@import '@mri/scss-all/scss/components/01-atoms/body/_body.scss';
// @import '@mri/scss-all/scss/components/01-atoms/caret/_caret.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/caret/_caret.scss';
// @import '@mri/scss-all/scss/components/01-atoms/checkbox/_checkbox.scss';
// @import '@mri/scss-all/scss/components/01-atoms/chevron/_chevron.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/chevron/_chevron.scss';
@import '@mri/scss-all/scss/components/01-atoms/counter/_counter.scss';
@import '@mri/scss-all/scss/components/01-atoms/grid/_grid.defaults.scss';

/*
// NOTE: using bootstrap-grid for layout instead of @mri/scss-layout/scss/grid-basic.scss or 
         components/01-atoms/grid/_grid.scss because it's much smaller and more feature rich
*/

@import '@mri/scss-all/scss/components/01-atoms/heading/_heading.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/heading/_heading.scss';
// @import '@mri/scss-all/scss/components/01-atoms/hotkey/_hotkey.scss';
@import '@mri/scss-all/scss/components/01-atoms/icon/_icon.scss';
@import '@mri/scss-all/scss/components/01-atoms/input/_input.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/input/_input.scss';
@import '@mri/scss-all/scss/components/01-atoms/label/_label.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/label/_label.scss';
@import '@mri/scss-all/scss/components/01-atoms/layout/_layout.scss';
@import '@mri/scss-all/scss/components/01-atoms/link/_link.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/link/_link.scss';
// @import '@mri/scss-all/scss/components/01-atoms/mark/_mark.scss';
@import '@mri/scss-all/scss/components/01-atoms/overlay/_overlay.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/overlay/_overlay.scss';
// @import '@mri/scss-all/scss/components/01-atoms/paragraph/_paragraph.scss';
@import '@mri/scss-all/scss/components/01-atoms/popover/_popover.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/popover/_popover.scss';
// @import '@mri/scss-all/scss/components/01-atoms/radio-button/_radio-button.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/radio-button/_radio-button.scss';
// @import '@mri/scss-all/scss/components/01-atoms/select/_select.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/select/_select.scss';
@import '@mri/scss-all/scss/components/01-atoms/spinner/_spinner.defaults.scss';
@import '@mri/scss-all/scss/components/01-atoms/spinner/_spinner.scss';
// @import '@mri/scss-all/scss/components/01-atoms/switch/_switch.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/switch/_switch.scss';
// @import '@mri/scss-all/scss/components/01-atoms/token/_token.defaults.scss';
// @import '@mri/scss-all/scss/components/01-atoms/token/_token.scss';
// @import '@mri/scss-all/scss/components/01-atoms/tooltip/_tooltip.scss';
// @import '@mri/scss-all/scss/components/02-molecules/accordion/_accordion.scss';
// @import '@mri/scss-all/scss/components/02-molecules/alert/_alert.scss';
// @import '@mri/scss-all/scss/components/02-molecules/autocomplete/_autocomplete.defaults.scss';
// @import '@mri/scss-all/scss/components/02-molecules/autocomplete/_autocomplete.scss';
// @import '@mri/scss-all/scss/components/02-molecules/breadcrumb/_breadcrumb.defaults.scss';
// @import '@mri/scss-all/scss/components/02-molecules/breadcrumb/_breadcrumb.scss';
@import '@mri/scss-all/scss/components/02-molecules/button/_button.defaults.scss';
@import '@mri/scss-all/scss/components/02-molecules/button/_button.scss';
@import '@mri/scss-all/scss/components/02-molecules/card/_card.scss';
@import '@mri/scss-all/scss/components/02-molecules/carousel/_carousel.scss';
@import '@mri/scss-all/scss/components/02-molecules/column-view/_column-view.scss';
@import '@mri/scss-all/scss/components/02-molecules/donut-chart/_donut-chart.scss';
@import '@mri/scss-all/scss/components/02-molecules/dropdown-button/_dropdown-button.defaults.scss';
@import '@mri/scss-all/scss/components/02-molecules/dropdown-button/_dropdown-button.scss';
@import '@mri/scss-all/scss/components/02-molecules/field/_field.scss';
// @import '@mri/scss-all/scss/components/02-molecules/hider/_hider.scss';
@import '@mri/scss-all/scss/components/02-molecules/icon-button/_icon-button.scss';
@import '@mri/scss-all/scss/components/02-molecules/icon-button/icon-button.scss';
// @import '@mri/scss-all/scss/components/02-molecules/kpi/_kpi.scss';
@import '@mri/scss-all/scss/components/02-molecules/link-menu/_link-menu.scss';
// @import '@mri/scss-all/scss/components/02-molecules/list/list.scss';
// @import '@mri/scss-all/scss/components/02-molecules/list-view/_list-view.scss';
@import '@mri/scss-all/scss/components/02-molecules/menu/_menu.defaults.scss';
@import '@mri/scss-all/scss/components/02-molecules/menu/_menu.scss';
// @import '@mri/scss-all/scss/components/02-molecules/number-kpi/_number-kpi.scss';
// @import '@mri/scss-all/scss/components/02-molecules/panel/_panel.scss';
// @import '@mri/scss-all/scss/components/02-molecules/side-bar/_side-bar.defaults.scss';
// @import '@mri/scss-all/scss/components/02-molecules/side-bar/_side-bar.scss';
// @import '@mri/scss-all/scss/components/02-molecules/split-button/_split-button.scss';
// @import '@mri/scss-all/scss/components/02-molecules/split-dropdown-button/_split-dropdown-button.scss';
// @import '@mri/scss-all/scss/components/02-molecules/table/_table.defaults.scss';
// @import '@mri/scss-all/scss/components/02-molecules/table/_table.scss';
@import '@mri/scss-all/scss/components/02-molecules/toggle-group/_toggle-group.defaults.scss';
@import '@mri/scss-all/scss/components/02-molecules/toggle-group/_toggle-group.scss';
@import '@mri/scss-all/scss/components/02-molecules/toolbar/_toolbar.defaults.scss';
@import '@mri/scss-all/scss/components/02-molecules/toolbar/_toolbar.scss';
// @import '@mri/scss-all/scss/components/02-molecules/tree-view/_tree-view.scss';
@import '@mri/scss-all/scss/components/03-organisms/app-menu/app-menu.scss';
// @import '@mri/scss-all/scss/components/03-organisms/banner-message/_banner-message.scss';
// @import '@mri/scss-all/scss/components/03-organisms/calendar/_calendar.scss';
// @import '@mri/scss-all/scss/components/03-organisms/dashboard-panel/_dashboard-panel.scss';
@import '@mri/scss-all/scss/components/03-organisms/datalist/_datalist.scss';
// @import '@mri/scss-all/scss/components/03-organisms/dialog-modal/_dialog.defaults.scss';
// @import '@mri/scss-all/scss/components/03-organisms/dialog-modal/_dialog.scss';
// @import '@mri/scss-all/scss/components/03-organisms/donut-chart-list/_donut-chart-list.scss';
@import '@mri/scss-all/scss/components/03-organisms/drawer-modal/_drawer.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/drawer-modal/_drawer.scss';
// @import '@mri/scss-all/scss/components/03-organisms/form-block/_form-block.defaults.scss';
// @import '@mri/scss-all/scss/components/03-organisms/form-block/_form-block.scss';
// @import '@mri/scss-all/scss/components/03-organisms/form/_form.defaults.scss';
// @import '@mri/scss-all/scss/components/03-organisms/form/_form.scss';
// @import '@mri/scss-all/scss/components/03-organisms/full-screen-modal/_full-modal--ag.scss';
// @import '@mri/scss-all/scss/components/03-organisms/full-screen-modal/_full-modal.defaults.scss';
// @import '@mri/scss-all/scss/components/03-organisms/full-screen-modal/_full-modal.scss';
// @import '@mri/scss-all/scss/components/03-organisms/kpi-list/_kpi-list.scss';
// @import '@mri/scss-all/scss/components/03-organisms/kpi-table/_kpi-table.scss';
@import '@mri/scss-all/scss/components/03-organisms/master-detail-view/_master-detail-view.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/master-detail-view/_master-detail-view.scss';
@import '@mri/scss-all/scss/components/03-organisms/menu-bar/_menu-bar.scss';
// @import '@mri/scss-all/scss/components/03-organisms/navigation-bar/_navigation-bar.defaults.scss';
// @import '@mri/scss-all/scss/components/03-organisms/navigation-bar/_navigation-bar.scss';
@import '@mri/scss-all/scss/components/03-organisms/page-footer/_page-footer.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/page-footer/_page-footer.scss';
@import '@mri/scss-all/scss/components/03-organisms/page-header-filter/_page-header-filter.scss';
@import '@mri/scss-all/scss/components/03-organisms/page-header/_page-header.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/page-header/_page-header.scss';
// @import '@mri/scss-all/scss/components/03-organisms/panel/_panel.scss';
@import '@mri/scss-all/scss/components/03-organisms/process-tab-bar/_process-tab-bar.scss';
@import '@mri/scss-all/scss/components/03-organisms/tab-bar/_tab-bar.scss';
// @import '@mri/scss-all/scss/components/03-organisms/tab-view/_tab-view.scss';
// @import '@mri/scss-all/scss/components/03-organisms/tag-field/_tag-field.scss';
// @import '@mri/scss-all/scss/components/03-organisms/toast-message/_toast-message.scss';
@import '@mri/scss-all/scss/components/03-organisms/toggle-list/_toggle-list.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/toggle-list/_toggle-list.scss';
@import '@mri/scss-all/scss/components/03-organisms/filter-bar/_filter-bar.defaults.scss';
@import '@mri/scss-all/scss/components/03-organisms/filter-bar/_filter-bar.scss';

@import '@mri/scss-all/scss/utilities/_all.scss';
