@use "sass:math";

$mri-font-size-headings: (
  small:    1rem,
  medium:   math.div(18, 16) * 1rem,
  large:    math.div(21, 16) * 1rem,
  x-large:  math.div(24, 16) * 1rem,
  2x-large: math.div(28, 16) * 1rem,
  3x-large: 2rem,
  4x-large: math.div(40, 16) * 1rem,
) !default;

$mri-line-height-headings: (
  small:    math.div(20, 16) * 1rem,
  medium:   math.div(24, 16) * 1rem,
  large:    math.div(28, 16) * 1rem,
  x-large:  math.div(32, 16) * 1rem,
  2x-large: math.div(36, 16) * 1rem,
  3x-large: math.div(40, 16) * 1rem,
  4x-large: math.div(48, 16) * 1rem,
) !default;

$mri-font-size-labels: (
  2x-small: math.div(8, 16) * 1rem,
  x-small:  math.div(10, 16) * 1rem,
  small:    math.div(12, 16) * 1rem,
  medium:   math.div(14, 16) * 1rem,
  large:    1rem,
  x-large:  math.div(18, 16) * 1rem,
) !default;

$mri-line-height-labels: (
  small:    1rem,
  medium:   math.div(20, 16) * 1rem,
  large:    math.div(24, 16) * 1rem,
  x-large:  math.div(24, 16) * 1rem,
) !default;

$mri-font-size-paragraphs: (
  small:    math.div(12, 16) * 1rem,
  medium:   math.div(14, 16) * 1rem,
  large:    1rem,
  x-large:  math.div(18, 16) * 1rem,
  2x-large: math.div(21, 16) * 1rem,
) !default;

$mri-line-height-paragraphs: (
  small:    math.div(20, 16) * 1rem,
  medium:   math.div(24, 16) * 1rem,
  large:    math.div(28, 16) * 1rem,
  x-large:  math.div(32, 16) * 1rem,
  2x-large: math.div(36, 16) * 1rem,
) !default;

@function mri-font-size($value: medium, $category: labels) {
  @if ($category == headings) {
    @return map-get($mri-font-size-headings, $value);
  }
  @else if ($category == paragraphs) {
    @return map-get($mri-font-size-paragraphs, $value);
  }
  @return map-get($mri-font-size-labels, $value);
}

@function mri-line-height($value: medium, $category: labels) {
  @if ($category == headings) {
    @return map-get($mri-line-height-headings, $value);
  }
  @else if ($category == paragraphs) {
    @return map-get($mri-line-height-paragraphs, $value);
  }
  @return map-get($mri-line-height-labels, $value);
}

$mri-font-stack-roboto:       "Roboto", "Segoe UI", "Arial", sans-serif !default;
$mri-font-stack-work-sans:    "Work Sans", "Gill Sans", "Futura", sans-serif !default;
$mri-font-stack-noto-sans:    "Noto Sans", "Work Sans", "Futura", sans-serif !default;
$mri-font-stack-noto-sans-light: "Noto Sans Light", "Roboto Light", "Futura", sans-serif !default;
$mri-font-stack-noto-mono:    "Noto Sans Mono", monospace !default;
$mri-font-stack-roboto-mono:  "Roboto Mono", "Consolas", monospace !default;

$mri-font-family-default:     $mri-font-stack-noto-sans !default;
$mri-font-family-numbers:     $mri-font-stack-noto-sans !default;
$mri-font-family-headings:    $mri-font-stack-noto-sans-light !default;
$mri-font-family-monospace:   $mri-font-stack-noto-mono !default;

$mri-font-size-base:          #{mri-font-size(medium, labels)} !default;

$mri-font-size-small:         #{mri-font-size(small, labels)} !default;
$mri-font-size-x-small:        #{mri-font-size(x-small, labels)} !default;
$mri-font-size-2x-small:       #{mri-font-size(2x-small, labels)} !default;

$mri-font-size-large:         #{mri-font-size(large, labels)} !default;
$mri-font-size-xlarge:        #{mri-font-size(x-large, labels)} !default;
$mri-font-size-xxlarge:       #{mri-font-size(2x-large, labels)} !default;

$mri-dark-bold-weight:        700 !default;
$mri-dark-normal-weight:      500 !default;
$mri-dark-light-weight:       300 !default;

$mri-light-bold-weight:       600 !default;
$mri-light-normal-weight:     400 !default;
$mri-light-light-weight:      300 !default;

