.#{$mri-ns}toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: mri-spacing(small) 0;
}

.#{$mri-ns}toolbar > :not(.#{$mri-ns}toolbar__group),
.#{$mri-ns}toolbar__group > * {
  margin-top: mri-spacing(hairline);
  margin-bottom: mri-spacing(hairline);
  margin-right: mri-spacing(small);
}

.#{$mri-ns}toolbar__vertical {
  align-items: stretch;
  justify-content: flex-start;
  justify-items: center;
  align-content: space-between;
  padding: 0 mri-spacing(small);
}

.#{$mri-ns}toolbar__vertical > :not(.#{$mri-ns}toolbar__group),
.#{$mri-ns}toolbar__vertical > .#{$mri-ns}toolbar__group > * {
  margin-left: mri-spacing(hairline);
  margin-right: mri-spacing(hairline);
  margin-bottom: mri-spacing(small);
}


.#{$mri-ns}toolbar > :last-child,
.#{$mri-ns}toolbar__group:last-child > :last-child {
  margin-right: 0;
}

.#{$mri-ns}toolbar > * + :not(.#{$mri-ns}toolbar__group),
.#{$mri-ns}toolbar__group > * + * {
  flex-basis: auto;
  flex-grow: 0;
}

.#{$mri-ns}toolbar--align-start {
  justify-content: flex-start;
}

.#{$mri-ns}toolbar--align-end {
  justify-content: flex-end;
}

.#{$mri-ns}toolbar--vertical {
  flex-direction: column;
}

.#{$mri-ns}toolbar__group {
  display: flex;
  flex-flow: inherit; //use the same flex-flow as parent
  align-items: inherit;
  max-width: max-content; // shrink-wrap to content
  max-height: max-content;
  margin: 0;
  padding: 0;
}

.#{$mri-ns}toolbar__group .#{$mri-ns}field{
  width: auto;
}

.#{$mri-ns}toolbar__text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  line-height: 1;
  white-space: nowrap;
  // margin-top: 0;
  // margin-bottom: 0;
  // margin-left: 0;
  // margin-right: 0;

  min-height: calc(1em + #{mri-spacing(x-small) * 2});
}

.#{$mri-ns}toolbar__image {
  height: $mri-toolbar-image-size;
  width: auto;
}

.#{$mri-ns}toolbar__spacer {
  width: mri-spacing(small);
  height: mri-spacing(small);
}
