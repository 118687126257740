.#{$mri-ns}datalist {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  flex-grow: 1;
}

.#{$mri-ns}datalist__list {
  @extend %list-reset;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  position: relative;
  user-select: none;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  height: 23rem;
  outline: 0px solid transparent;
}

.#{$mri-ns}datalist__header {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: mri-spacing(small);
  grid-template-columns: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.#{$mri-ns}datalist__heading {
  grid-row: 1;
  grid-column: 1 / span 2;
}

.#{$mri-ns}datalist__search-field {
  grid-row: 1;
  grid-column: 1 / span 2;
}

.#{$mri-ns}datalist__heading + .#{$mri-ns}datalist__search-field {
  grid-column: 2 / span 1;
}


.#{$mri-ns}datalist__metadata-1,
.#{$mri-ns}datalist__metadata-2 {
  display: block;
  grid-row: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.#{$mri-ns}datalist__heading ~ .#{$mri-ns}datalist__metadata-1, 
.#{$mri-ns}datalist__heading ~ .#{$mri-ns}datalist__metadata-2, 
.#{$mri-ns}datalist__search-field ~ .#{$mri-ns}datalist__metadata-1, 
.#{$mri-ns}datalist__search-field ~ .#{$mri-ns}datalist__metadata-2 {
  grid-row: 2;
}


.#{$mri-ns}datalist__metadata-1 {
  grid-column: 1;
}

.#{$mri-ns}datalist__metadata-2 {
  grid-column: 2;
  text-align: right;
}


.#{$mri-ns}datalist__item {
  display: grid;
  flex-shrink: 0;
  flex-grow: 0;
  grid-template-columns: auto;
  grid-template-rows: auto;
  border-width: 0 0 1px 0;
  border-style: solid;
  outline: 0px solid transparent;
  transition: background-color $mri-transition-base;

  &:not([aria-disabled="true"]) {
    cursor: pointer;
  }

}

.#{$mri-ns}datalist__item--tools {
  position: relative;

  .#{$mri-ns}datalist__item-toolbar {
    position: absolute;
  }
}

.#{$mri-ns}datalist__item-checkbox {
  display:none;
}


.#{$mri-ns}datalist__item-header {
  grid-column: 1;
  grid-row: 1;
  display: inline-block;
  margin-top: 2px;
  align-self: center;
  overflow: hidden;
}

.#{$mri-ns}datalist__item-title, .#{$mri-ns}datalist__item-subtitle {
  display: block;
}

.#{$mri-ns}datalist__item-truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.#{$mri-ns}datalist__item-data-area {
  grid-column: 1;
  grid-row: 2;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: mri-spacing(small);
  flex-flow: row wrap;
  min-width: 0;
  min-height: 0;
  margin-top: mri-spacing(x-small);

  .#{$mri-ns}datalist__item-data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .#{$mri-ns}datalist__item-data--1 {
    grid-column: 1;
    text-align: left;
  }

  .#{$mri-ns}datalist__item-data--2 {
    grid-column: 2;
    text-align: left;
  }
}

.#{$mri-ns}datalist__item-paragraph {
  grid-column: 1;
  grid-row: 3;
  display: inline-block;
  margin: 0;
  margin-top: mri-spacing(x-small);
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;

  @supports (-webkit-box-orient: vertical) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.#{$mri-ns}datalist__item--icon {
  grid-template-columns: 32px auto;

  .#{$mri-ns}datalist__item-icon {
    grid-row: 1;
    grid-column: 1;
  }

  .#{$mri-ns}datalist__item-header,
  .#{$mri-ns}datalist__item-data-area,
  .#{$mri-ns}datalist__item-paragraph {
    grid-column: 2;
  }
  .#{$mri-ns}datalist__item-header {
    margin-top: 0;
  }
}

.#{$mri-ns}datalist--horizontal {
  .#{$mri-ns}datalist__item {
    grid-template-columns: auto;
    grid-row-gap: mri-spacing(x-small);

    .#{$mri-ns}datalist__item-header {
      grid-column: 1;
    }

    .#{$mri-ns}datalist__item-paragraph {
      grid-column: 1 / span 2;
      grid-row: 2; 
    }
  
    .#{$mri-ns}datalist__item-data-area {
      grid-column: 2;
      grid-row: 1;
      display: inline-block;
      margin-top: 0;
    
      .#{$mri-ns}datalist__item-data--1 {
        text-align: right;
        display: block;
      }
      .#{$mri-ns}datalist__item-data--2 {
        text-align: right;
        display: block;
      }
    }
  }
  
  // with datalist__item-icon
  .#{$mri-ns}datalist__item--icon {
    grid-template-columns: 32px auto auto;

    .#{$mri-ns}datalist__item-icon {
      grid-row: 1;
      grid-column: 1;
    }

    .#{$mri-ns}datalist__item-header {
      grid-column: 2;

    }

    .#{$mri-ns}datalist__item-paragraph {
      grid-column: 2;
      grid-row: 2; 
      margin-top: 0
    }
  
    .#{$mri-ns}datalist__item-data-area {
      grid-column: 3;
      grid-row: 1 / span 2;
      margin-top: 0;
    }
  }
}

.#{$mri-ns}datalist--multiselect {
  @media (hover: none) and (pointer: coarse) {
    .#{$mri-ns}datalist__item {
      grid-template-columns: 24px auto;
    
      .#{$mri-ns}datalist__item-checkbox {
        display: initial;
        grid-column: 1;
        grid-row: 1;
        align-self: flex-start;
      }
      
      .#{$mri-ns}datalist__item-header, 
      .#{$mri-ns}datalist__item-data-area, 
      .#{$mri-ns}datalist__item-paragraph {
        grid-column: 2;
      }
      
      &.#{$mri-ns}datalist__item--icon {
        grid-template-columns: 24px 32px auto;
      
        .#{$mri-ns}datalist__item-icon {
          grid-column: 2;
        }
      
        .#{$mri-ns}datalist__item-header, 
        .#{$mri-ns}datalist__item-data-area, 
        .#{$mri-ns}datalist__item-paragraph {
          grid-column: 3;
        }
      }
    }
    
    &.#{$mri-ns}datalist--horizontal {
      .#{$mri-ns}datalist__item {
        grid-template-columns: 24px auto;
    
        .#{$mri-ns}datalist__item-header {
          grid-column: 2;
        }
        
        .#{$mri-ns}datalist__item-paragraph {
          grid-column: 2 /  span 2;
        }
      
        .#{$mri-ns}datalist__item-data-area {
          grid-column: 3;
        }
      }
      
      .#{$mri-ns}datalist__item--icon {
        grid-template-columns: 24px 32px auto;
        
        .#{$mri-ns}datalist__item-icon {
          grid-row: 1;
          grid-column: 2;
        }
      
        .#{$mri-ns}datalist__item-header {
          grid-column: 3;
        }
        
        .#{$mri-ns}datalist__item-paragraph {
          grid-column: 3 /  span 2;
        }
      
        .#{$mri-ns}datalist__item-data-area {
          grid-column: 4;
        }
      }
    }
  }
}

.#{$mri-ns}listbox {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "col1 toolbar col2";
  -ms-grid-columns: auto;
  grid-template-columns: auto 48px auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-column-gap: mri-spacing(medium);
  margin: 0 mri-spacing(large);

  .#{$mri-ns}listbox__col1 {
    grid-area: col1;
  }

  .#{$mri-ns}listbox__col2 {
    grid-area: col2;
  }

  .#{$mri-ns}listbox__toolbar {
    grid-area: toolbar;
    padding: mri-spacing(large) 0;
    display: flex;
    align-items: center;
      > * {
        flex: 1;
    }
  }

  .#{$mri-ns}datalist {
    border-style: solid;
    border-width: 1px;
  }
}


// Size variants

@mixin component-datalist-size-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}datalist--" + $variant-name + " ", null);

  #{$parent-class}.#{$mri-ns}datalist__header {
    padding: map-get($options, "header-padding");
    grid-column-gap: map-get($options, "header-column-gap");
  }

  #{$parent-class}.#{$mri-ns}datalist__metadata-1,
  #{$parent-class}.#{$mri-ns}datalist__metadata-2 {
    font-size: map-get($options, metadata-font-size);
    line-height: map-get($options, metadata-line-height);
  }
  #{$parent-class}.#{$mri-ns}datalist__list:focus {
    outline-width: map-get($options, list-focus-outline-width);
    outline-offset: map-get($options, list-focus-outline-offset);
  }

    #{$parent-class}.#{$mri-ns}datalist__item:focus {
    outline-width: map-get($options, list-focus-outline-width);
    outline-offset: map-get($options, list-focus-outline-offset);
  }

  #{$parent-class}.#{$mri-ns}datalist__item {
    padding: map-get($options, item-padding);
    grid-column-gap: map-get($options, "item-column-gap");

    &[aria-activedescendant],
    .#{$mri-ns}is-highlighted {
      outline-width: map-get($options, item-highlighted-outline-width);
      outline-offset: map-get($options, item-highlighted-outline-offset);
    }
  }

  #{$parent-class}.#{$mri-ns}datalist__item-title {
    line-height: map-get($options, item-title-line-height);
    font-size: map-get($options, item-title-font-size);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-subtitle {
    line-height: map-get($options, item-subtitle-line-height);
    font-size: map-get($options, item-subtitle-font-size);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-data {
    line-height: map-get($options, item-data-line-height);
    font-size: map-get($options, item-data-font-size);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-paragraph {
    line-height: map-get($options, item-paragraph-line-height);
    font-size: map-get($options, item-paragraph-font-size);
    max-height: calc(#{map-get($options, item-paragraph-line-height)} * #{map-get($options, item-paragraph-font-size)} * 2);
  }

  #{$parent-class}.#{$mri-ns}datalist__item.#{$mri-ns}datalist__item--tools {
    //padding-right: 38px;

    #{$parent-class}.#{$mri-ns}datalist__item-toolbar {
      top: mri-spacing(small);
      right: mri-spacing(medium);
    }

    #{$parent-class}.#{$mri-ns}toolbar {
      padding: 0;
    }
  }

  #{$parent-class}.#{$mri-ns}datalist__footer {
    padding: map-get($options, footer-padding);
  }

  #{$parent-class}.#{$mri-ns}datalist__footer-toolbar {
    padding: 0;
  }
}

$mri-datalist-default-size: (
  item-title-font-size: $mri-font-size-base,
  item-title-line-height: 1.3,
  item-subtitle-font-size: $mri-font-size-small,
  item-subtitle-line-height: 1.2,
  item-data-font-size: $mri-font-size-small,
  item-data-line-height: 1.5,
  item-paragraph-font-size: $mri-font-size-small,
  item-paragraph-line-height: 1.5,
  header-padding: mri-spacing(small) mri-spacing(medium),
  header-column-gap: mri-spacing(medium),
  metadata-font-size: $mri-font-size-small,
  metadata-line-height: 1.5,
  list-focus-outline-width: 2px,
  list-focus-outline-offset: -2px,
  item-padding: mri-spacing(small) mri-spacing(medium),
  item-column-gap: mri-spacing(small),
  item-highlighted-outline-width: 2px,
  item-highlighted-outline-offset: -2px,
  footer-padding: mri-spacing(small) mri-spacing(medium),
) !default;

@include component-datalist-size-variant($mri-datalist-default-size, null);

// Color variants

@mixin component-datalist-color-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}datalist--" + $variant-name + " ", null);

  #{$parent-class}.#{$mri-ns}datalist__header {
    background-color: map-get($options, header-bg-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__metadata-1,
  #{$parent-class}.#{$mri-ns}datalist__metadata-2 {
    color: map-get($options, metadata-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__list {
    background-color: map-get($options, list-bg-color);

    &:focus {
      outline-color: map-get($options, list-focus-outline-color);
    }
  }

  #{$parent-class}.#{$mri-ns}datalist__item {
    background-color: map-get($options, item-bg-color);
    border-bottom-color: map-get($options, item-border-color);
    
    &:hover {
      background-color: map-get($options, item-hover-bg-color);
    }

    &:focus {
      outline-color: map-get($options, item-highlighted-outline-color);
    }

    &:active {
      background-color: map-get($options, item-active-bg-color);
    }

  }

  #{$parent-class}.#{$mri-ns}datalist__item[aria-disabled="true"],
  #{$parent-class}.#{$mri-ns}datalist__item.#{$mri-ns}is-disabled {
    background-color: map-get($options, item-disabled-bg-color);
    opacity: map-get($options, item-disabled-opacity);

    &:hover, &:active {
      background-color: map-get($options, item-disabled-bg-color);
    }

    .#{$mri-ns}datalist__item-title {
      color: map-get($options, item-disabled-title-color);
    }
    
    .#{$mri-ns}datalist__item-data {
      color: map-get($options, item-disabled-data-color);
    }

    .#{$mri-ns}datalist__item-paragraph {
      color: map-get($options, item-disabled-paragraph-color);
    }
  }

  #{$parent-class}.#{$mri-ns}datalist__item[aria-selected="true"],
  #{$parent-class}.#{$mri-ns}datalist__item.#{$mri-ns}is-selected {
    background-color: map-get($options, item-selected-bg-color);

    &:hover {
      background-color: map-get($options, item-selected-hover-bg-color);
    }

    &:active {
      background-color: map-get($options, item-selected-active-bg-color);
    }

    .#{$mri-ns}datalist__item-title {
      color: map-get($options, item-selected-title-color);
    }

    .#{$mri-ns}datalist__item-subtitle {
      color: map-get($options, item-selected-subtitle-color);
    }

    .#{$mri-ns}datalist__item-icon {
      border-color: map-get($options, item-selected-icon-border-color);
    }
  
    .#{$mri-ns}datalist__item-data {
      color: map-get($options, item-selected-data-color);
    }

    .#{$mri-ns}datalist__item-paragraph {
      color: map-get($options, item-selected-paragraph-color);
    }
  }
    
  #{$parent-class}.#{$mri-ns}datalist__item[aria-selected="true"].#{$mri-ns}is-secondary-selected,
  #{$parent-class}.#{$mri-ns}datalist__item.#{$mri-ns}is-secondary-selected {
    background-color: map-get($options, item-secondary-selected-bg-color);

    &:hover {
      background-color: map-get($options, item-secondary-selected-hover-bg-color);
    }

    &:active {
      background-color: map-get($options, item-secondary-selected-active-bg-color);
    }

    .#{$mri-ns}datalist__item-title {
      color: map-get($options, item-secondary-selected-title-color);
    }

    .#{$mri-ns}datalist__item-icon {
      border-color: map-get($options, item-secondary-selected-icon-border-color);
    }

    .#{$mri-ns}datalist__item-data {
      color: map-get($options, item-secondary-selected-data-color);
    }

    .#{$mri-ns}datalist__item-paragraph {
      color: map-get($options, item-secondary-selected-paragraph-color);
    }
  }

  #{$parent-class}.#{$mri-ns}datalist__item[aria-activedescendant],
  #{$parent-class}.#{$mri-ns}datalist__item.#{$mri-ns}is-highlighted {
    outline-color: map-get($options, item-highlighted-outline-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-title {
    color: map-get($options, item-title-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-icon {
    border-color: map-get($options, item-icon-border-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-data {
    color: map-get($options, item-data-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__item-paragraph {
    color: map-get($options, item-paragraph-color);
  }

  #{$parent-class}.#{$mri-ns}datalist__footer {
    background-color: map-get($options, footer-bg-color);
  }

  .#{$mri-ns}listbox {
    #{$parent-class}.#{$mri-ns}datalist {
      border-color: map-get($options, header-bg-color);
    }
  }
}

$mri-datalist-light-default: (
  header-bg-color: $mri-background-color-lighter,
  metadata-color: $mri-dark-text-color-muted,
  list-bg-color: $mri-background-color-white,
  list-focus-outline-color: none,
  item-bg-color: $mri-transparent,
  item-border-color: $mri-dark-line-color-faint,
  item-hover-bg-color: $mri-light-hover-color-transparent,
  item-active-bg-color: $mri-light-active-color-transparent,
  item-title-color: $mri-dark-text-color,
  item-subtitle-color: $mri-dark-text-color-faint,
  item-icon-border-color: $mri-transparent,
  item-data-color: $mri-dark-text-color-muted,
  item-paragraph-color: $mri-dark-text-color-muted,
  item-disabled-bg-color: $mri-background-color-white,
  item-disabled-opacity: $mri-disabled-opacity,
  item-disabled-title-color: $mri-dark-text-color-disabled,
  item-disabled-data-color: $mri-dark-text-color-disabled,
  item-disabled-paragraph-color: $mri-dark-text-color-disabled,
  item-selected-bg-color: $mri-light-selected-color-transparent,
  item-selected-hover-bg-color: $mri-light-selected-hover-color-transparent,
  item-selected-active-bg-color: $mri-light-selected-active-color-transparent,
  item-selected-icon-border-color:$mri-transparent,
  item-selected-title-color: $mri-dark-text-color,
  item-selected-subtitle-color: $mri-dark-text-color,
  item-selected-data-color: $mri-dark-text-color,
  item-selected-paragraph-color: $mri-dark-text-color,
  item-highlighted-outline-color: $mri-light-focus-color,  
  item-secondary-selected-bg-color: $mri-light-secondary-selected-color-transparent,
  item-secondary-selected-hover-bg-color: $mri-light-secondary-selected-hover-color-transparent,
  item-secondary-selected-active-bg-color: $mri-light-secondary-selected-active-color-transparent,
  item-secondary-selected-icon-border-color: $mri-transparent,
  item-secondary-selected-title-color: $mri-dark-text-color,
  item-secondary-selected-data-color: $mri-dark-text-color,
  item-secondary-selected-paragraph-color: $mri-dark-text-color,
  footer-bg-color: $mri-background-color-lighter,
) !default;

@include component-datalist-color-variant($mri-datalist-light-default, null);

$mri-datalist-dark: (
  header-bg-color: $mri-background-color-darker,
  metadata-color: $mri-light-text-color-muted,
  list-bg-color: $mri-transparent,
  list-focus-outline-color: none,
  item-bg-color: $mri-transparent,
  item-border-color: $mri-light-line-color-faint,
  item-hover-bg-color: $mri-dark-hover-color-transparent,
  item-active-bg-color: $mri-dark-active-color-transparent,
  item-title-color: $mri-light-text-color,
  item-subtitle-color: $mri-light-text-color-faint,
  item-icon-border-color: $mri-light-line-color,
  item-data-color: $mri-light-text-color-muted,
  item-paragraph-color: $mri-light-text-color-muted,
  item-disabled-bg-color: $mri-background-color-white,
  item-disabled-opacity: $mri-disabled-opacity,
  item-disabled-title-color: $mri-light-text-color-disabled,
  item-disabled-data-color: $mri-light-text-color-disabled,
  item-disabled-paragraph-color: $mri-light-text-color-disabled,
  item-selected-bg-color: $mri-dark-selected-color-transparent,
  item-selected-hover-bg-color: $mri-dark-selected-hover-color-transparent,
  item-selected-active-bg-color: $mri-dark-selected-active-color-transparent,
  item-selected-icon-border-color:$mri-light-line-color,
  item-selected-title-color: $mri-light-text-color,
  item-selected-subtitle-color: $mri-light-text-color,
  item-selected-data-color: $mri-light-text-color,
  item-selected-paragraph-color: $mri-light-text-color,
  item-highdarked-outline-color: $mri-dark-focus-color,  
  item-secondary-selected-bg-color: $mri-dark-secondary-selected-color-transparent,
  item-secondary-selected-hover-bg-color: $mri-dark-secondary-selected-hover-color-transparent,
  item-secondary-selected-active-bg-color: $mri-dark-secondary-selected-active-color-transparent,
  item-secondary-selected-icon-border-color: $mri-light-line-color,
  item-secondary-selected-title-color: $mri-light-text-color,
  item-secondary-selected-data-color: $mri-light-text-color,
  item-secondary-selected-paragraph-color: $mri-light-text-color,
  footer-bg-color: $mri-background-color-darker,
  ) !default;

@include component-datalist-color-variant($mri-datalist-dark, "dark");
