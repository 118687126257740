.#{$mri-ns}donut-chart {
  display: inline-flex;
  flex-flow: column nowrap;
  white-space: nowrap;
}

.#{$mri-ns}donut-chart--xsmall {
  width: 85px;
  height: 85px !important;
}

.#{$mri-ns}donut-chart--small {
  width: 115px;
  height: 115px !important;
}

.#{$mri-ns}donut-chart--medium {
  width: 175px;
  height: 175px !important;
}

.#{$mri-ns}donut-chart--large {
  width: 250px;
  height: 250px !important;
}

.#{$mri-ns}donut-chart--xsmall-percent-label {
  font-size: 22px;
}

.#{$mri-ns}donut-chart--small-percent-label {
  font-size: 24px;
}

.#{$mri-ns}donut-chart--medium-percent-label {
  font-size: 32px;
}

.#{$mri-ns}donut-chart--large-percent-label {
  font-size: 48px;
}

.#{$mri-ns}donut-chart--xsmall-label {
  font-size: 12px;
}

.#{$mri-ns}donut-chart--small-label {
  font-size: 12px;
}

.#{$mri-ns}donut-chart--medium-label {
  font-size: 14px;
}

.#{$mri-ns}donut-chart--large-label {
  font-size: 18px;
}

.k-chart-donut-center {
    left: 0% !important;
    right: 0%;
    margin: 0 auto;
}