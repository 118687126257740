$mri-grid-columns:                  12 !default;
$mri-grid-breakpoints: (
// breakpoint-name: breakpoint-min-width
  xs: 0px,
  sm: 768px,
  md: 992px,
  lg: 1200px
) !default;
// ex: (sm: 768px) will yield classes like ".sm-1 { ... }"
$mri-grid-gutter-width:             $mri-spacing-gutter-width !default;
$mri-grid-horizontal-margin-width:  $mri-spacing-horizontal-margin-width !default;
$mri-grid-container-padding:        mri-spacing(medium) !default;

// Lists to loop through
$mri-grid-breakpoint-names:         map-keys($mri-grid-breakpoints) !default;
$mri-grid-breakpoint-widths:        map-values($mri-grid-breakpoints) !default;