.#{$mri-ns}link {
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 1px;
  margin-left: -1px;
  padding-right: 1px;
  margin-right: -1px;
  cursor: pointer;
  transition: 
    color $mri-transition-base,
}

.#{$mri-ns}link:link, .#{$mri-ns}link:visited {
  text-decoration: $mri-link-text-decoration;

  &:hover {
    text-decoration: $mri-link-hover-text-decoration;
  }
}

// Color variants

@mixin component-link-color-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}link--" + $variant-name + " ", null);

  .#{$mri-ns}link#{$modifier} {
    color: map-get($options, color);

    &:link {
      color: map-get($options, color);
    }

    &:visited {
      color: map-get($options, visited-color);
    }

    &:hover {
      color: map-get($options, hover-color);
    }

    &:active {
      color: map-get($options, active-color);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px map-get($options, focus-ring-color);
    }
  }
}

$mri-link-dark-default: (
  color: $mri-dark-interactive-color,
  visited-color: $mri-dark-link-visited-color,
  hover-color: shade($mri-dark-interactive-color, 50%),
  focus-ring-color: $mri-light-focus-color,
  active-color: shade($mri-dark-interactive-color, 80%),
);

@include component-link-color-variant($mri-link-dark-default, null);

$mri-link-light: (
  color: $mri-light-interactive-color,
  visited-color: $mri-light-link-visited-color,
  hover-color: tint($mri-light-interactive-color, 50%),
  focus-ring-color: $mri-dark-focus-color,
  active-color: tint($mri-light-interactive-color, 80%),
);

@include component-link-color-variant($mri-link-light, "light");

