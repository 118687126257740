.#{$mri-ns}hider {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  &--top {
    display: flex;
    flex-direction: column;
    gap: mri-spacing(medium);
  }
  &--bottom {
    display: flex;
    flex-direction: column-reverse;
  }
}

.#{$mri-ns}hider.#{$mri-ns}is-closed section > .#{$mri-ns}hider__content {
  height: 0;
  overflow: hidden;
}

.#{$mri-ns}hider__toggle {
  @extend %button-reset;

  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  transition:
    color $mri-transition-base,
    background-color $mri-transition-base;
}

.#{$mri-ns}hider__toggle:disabled {
  cursor: default;
}

.mri-hider__toggle-label {
  &--right {
    text-align: right;
    margin-left: auto;
  }
  &--center {
    text-align: center;
    margin: auto;
  }
  &--left {
    text-align: left;
    margin-right: auto;
  }
}

.#{$mri-ns}hider__toggle-caret {
  transform: rotate(180deg);
}

.#{$mri-ns}hider.#{$mri-ns}is-closed > section .#{$mri-ns}hider__toggle-caret {
  transform: rotate(0deg);
}

// Size variants

@mixin component-hider-size-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}hider--" + $variant-name + " ", null);
  
  #{$parent-class}.#{$mri-ns}hider__toggle {
    padding:
      map-get($options, toggle-padding-vertical)
      map-get($options, toggle-padding-horizontal);
    border-radius: 0;
  }
}

$mri-hider-default-size: (
  toggle-padding-vertical: mri-spacing(x-small),
  toggle-padding-horizontal: mri-spacing(x-small),
  toggle-border-radius: mri-border-radius(small)
) !default;

@include component-hider-size-variant($mri-hider-default-size, null);

// Color variants

@mixin component-hider-color-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}hider--" + $variant-name + " ", null);
  
  #{$parent-class}.#{$mri-ns}hider__toggle {
    color: map-get($options, toggle-color);
    background-color: map-get($options, toggle-bg-color);
    
    &:hover {
      color: map-get($options, toggle-hover-color);
      background-color: map-get($options, toggle-hover-bg-color);
    }
    
    &:active {
      color: map-get($options, toggle-active-color);
      background-color: map-get($options, toggle-active-bg-color);
    }
    
    &:focus {
      border-bottom: 2px solid  map-get($options, toggle-focus-ring-color);
    }
  }
  
}

$mri-hider-dark-default: (
  toggle-color: $mri-dark-interactive-color,
  toggle-bg-color: transparent,
  toggle-hover-color: $mri-dark-interactive-color,
  toggle-hover-bg-color: transparentize(mri-blue(40), 0.9),
  toggle-active-color: $mri-dark-interactive-color,
  toggle-active-bg-color: transparentize(mri-blue(40), 0.8),
  toggle-focus-ring-color: mri-blue(40)
) !default;

@include component-hider-color-variant($mri-hider-dark-default, null);