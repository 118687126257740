.#{$mri-ns}overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: fade-in $mri-transition-base;
  z-index: $mri-overlay-z-index;
  transition: opacity $mri-transition-base;
}

.#{$mri-ns}overlay.#{$mri-ns}is-hidden {
  pointer-events: none;
  animation: fade-out $mri-transition-base;
  opacity: 0;
}

.#{$mri-ns}overlay--loading {
  align-items: center;
  display: none;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.mri-is-visible {
    opacity: 1;
  }

  // success state default
  // ----------------------------------
  .success {
    display: none;

    &.block {
      display: block;
    }

    .mri-icon__success-lg-rev {
      opacity: 0;
      transition: opacity $mri-transition-base, 
                  transform $mri-transition-long;
      transform: scale(1);

      path {
        fill: $mri-dark-success-color;
      }
    }
  }

  // success state when active
  // ----------------------------------
  &.is-success {
    color: $mri-light-text-color;

    .loading {
      display: none;
    }

    .mri-icon__success-lg-rev {
      opacity: 1;
      transform: scale(1.25);
    }
  }
}


// Color variants

@mixin component-overlay-color-variant($options, $variant-name) {
  $modifier: if($variant-name, "--" + $variant-name, null);
  $parent-class: if($variant-name, ".#{$mri-ns}overlay--" + $variant-name + " ", null);

  .#{$mri-ns}overlay#{$modifier} {
    background-color: map-get($options, overlay-bg-color);
  }
}

$mri-overlay-dark: (
  overlay-bg-color: transparentize(mri-gray(), 0.5)
) !default;

@include component-overlay-color-variant($mri-overlay-dark, null);

$mri-overlay-light: (
  overlay-bg-color: transparentize(mri-gray(05), 0.5)
) !default;

@include component-overlay-color-variant($mri-overlay-light, "light");

